.terminal {
    background-color: #1e1e1e;
    color: #00ff00;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    height: 400px;
    overflow-y: scroll;
    white-space: pre-wrap;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
