.burger-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
}

.burger-icon span {
    background-color: white;
    height: 3px;
    width: 25px;
    margin: 2px 0;
}

.mobile-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    text-align: center;
    padding: 10px 0;
    z-index: 20;
}

.mobile-menu a {
    padding: 10px;
    color: white;
    display: block;
    text-decoration: none;
}

.mobile-menu a:hover {
    background-color: #1d4ed8;
}

.navbar-container {
    width: 100vw; /* Assure que la navbar occupe toute la largeur de la vue */
    overflow-x: hidden;
}
/* Mobile Styles for Navbar */
@media only screen and (max-width: 600px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-items {
    display: none;
  }

  .navbar-burger {
    display: block;
    cursor: pointer;
  }

  .navbar-active .navbar-items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
