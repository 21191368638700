.k8s-simulator-container {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.btn-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.btn-icon {
    background-color: #1d4ed8;
    color: white;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 180px;
    transition: background-color 0.3s ease;
}

.btn-icon:hover {
    background-color: #ed0707;
}

.progress-bar {
    width: 70%;
    background-color: #f3f4f6;
    height: 20px;
    border-radius: 10px;
    margin: 10px 0;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.2s;
}

.notification {
    margin: 10px;
    color: #10b981;
    font-weight: bold;
}

.simulator-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 95%; /* Augmenter la largeur disponible */
    max-width: 1200px; /* Assurer une limite pour les écrans larges */
    margin: 0 auto;
    padding: 20px 0;
}

.graph-container {
    flex: 2; /* Priorité donnée au graphique, il prendra plus de place */
    padding-right: 40px;
}

.terminal-container {
    flex: 1; /* Terminal plus petit mais toujours visible */
}

.terminal {
    background-color: #1e1e1e;
    color: #00ff00;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    height: 450px; /* Augmenter légèrement la hauteur */
    overflow-y: scroll;
    white-space: pre-wrap;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
}
/* Mobile Styles for Kubernetes Simulator */
@media only screen and (max-width: 600px) {
    .btn-container {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .btn-icon {
        width: 100%;
        font-size: 14px;
        padding: 12px 0;
    }

    .simulator-content {
        flex-direction: column;
        align-items: center;
    }

    .graph-container,
    .terminal-container {
        width: 100%;
        padding: 0;
    }

    .terminal {
        height: 300px;
    }

    .progress-bar {
        width: 100%;
    }
}
