@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fortawesome/fontawesome-free/css/all.min.css';
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Empêche le débordement horizontal */
}

* {
    box-sizing: border-box; /* Assure que la taille inclut padding et border */
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* Étape 3 : Styles pour Skills section (si pas de fichier CSS dédié) */
@media only screen and (max-width: 600px) {
    .grid-cols-2 {
        grid-template-columns: repeat(1, 1fr); /* Une seule colonne sur mobile */
    }

    .lg:grid-cols-4 {
        grid-template-columns: repeat(2, 1fr); /* Ajustement pour tablette */
    }

    .p-4 {
        padding: 10px; /* Réduire l'espacement autour des icônes */
    }

    .text-3xl {
        font-size: 2rem; /* Réduire la taille des icônes sur mobile */
    }
}

/* Étape 4 : Styles pour la section Work (Projets) */
@media only screen and (max-width: 600px) {
    .grid-cols-1 {
        grid-template-columns: 1fr; /* Une seule colonne sur mobile */
    }

    .grid-cols-2 {
        grid-template-columns: 1fr; /* Ajustement pour mobile */
    }

    .text-lg {
        font-size: 1rem; /* Ajustement des titres */
    }

    .text-gray-500 {
        font-size: 0.875rem; /* Ajustement du texte descriptif */
    }
}

/* Étape 5 : Ajustements globaux pour polices et marges */
@media only screen and (max-width: 600px) {
    .text-4xl {
        font-size: 2rem; /* Ajuster la taille des titres principaux */
    }

    .py-16 {
        padding: 10px 0; /* Réduire le padding vertical */
    }

    .mb-12 {
        margin-bottom: 20px; /* Réduire la marge inférieure */
    }

    .container {
        padding-left: 10px;
        padding-right: 10px; /* Réduire les marges latérales */
    }
}

/* Mobile Optimizations for Navbar */
@media only screen and (max-width: 600px) {
  .flex {
    flex-direction: column;
  }

  .text-2xl {
    font-size: 1.5rem;
  }

  .space-x-4 {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}

/* Global adjustments for small screens */
@media only screen and (max-width: 600px) {
  .text-4xl {
    font-size: 2rem;
  }

  .py-16 {
    padding: 10px 0;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
